import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Title,
  TopContainer,
} from "../Product/ProductElement";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { get, post, put, remove, serverName } from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  Button,
  Drawer,
  Form,
  Input,
  Upload,
  message,
  Select,
  Modal,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;

function Subcategory() {
  const [subcategories, setSubcategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingSubcategory, setEditingSubcategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  let token = localStorage.getItem("token");

  const getAllSubcategories = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/subcategories?token=${token}`);
      if (response.data.success) {
        setSubcategories(response.data.subcategories);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const getAllCategories = async () => {
    try {
      const response = await get(`/categories?token=${token}`);
      if (response.data.success) {
        setCategories(response.data.categories);
      }
    } catch (e) {
      console.error("Error fetching categories:", e);
    }
  };

  useEffect(() => {
    getAllSubcategories();
    getAllCategories();
  }, []);

  const handleEdit = (subcategory) => {
    setEditingSubcategory(subcategory);
    form.setFieldsValue(subcategory);
    setDrawerVisible(true);
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this subcategory?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    try {
      const res = await remove(`/admin/subcategory/${id}?token=${token}`);
      if (res.data.success) {
        setSubcategories(
          subcategories.filter((subcategory) => subcategory._id !== id)
        );
      }
    } catch (error) {
      console.error("There was an error deleting the subcategory!", error);
    }
  };

  const handleAddSubcategory = () => {
    setEditingSubcategory(null);
    form.resetFields();
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("categoryId", values.categoryId);

      if (values.image?.fileList) {
        formData.append("image", values.image.fileList[0].originFileObj);
      }

      let response;
      if (editingSubcategory) {
        response = await put(
          `/admin/subcategory/${editingSubcategory._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await post(
          `/admin/subcategory/new`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      if (response.data.success) {
        message.success(
          `Subcategory ${editingSubcategory ? "updated" : "added"} successfully`
        );
        onClose();
        getAllSubcategories();
      } else {
        message.error(
          `Failed to ${editingSubcategory ? "update" : "add"} subcategory`
        );
      }
    } catch (error) {
      console.error(
        `Error ${editingSubcategory ? "updating" : "adding"} subcategory:`,
        error
      );
      message.error(
        `Failed to ${editingSubcategory ? "update" : "add"} subcategory`
      );
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = subcategories.filter(
      (subcategory) =>
        subcategory.name.toLowerCase().includes(query) ||
        subcategory.description.toLowerCase().includes(query)
    );
    setSubcategories(filtered);
  };

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>Subcategories</Title>

        <Input
          placeholder="Search subcategories"
          value={searchQuery}
          onChange={handleSearch}
          style={{ marginRight: 10, width: 200 }}
        />

        <Button
          type="default"
          onClick={handleAddSubcategory}
          style={{ margin: 10 }}
        >
          Add Subcategory
        </Button>
      </TopContainer>
      <Table>
        <Thead>
          <tr>
            <Th>Id</Th>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Category</Th>
            <Th>Image</Th>
            <Th>Action</Th>
          </tr>
        </Thead>
        <Tbody>
          {isLoading && <LoadingSpinner />}
          {subcategories.map((subcategory) => (
            <tr key={subcategory?._id}>
              <Td>{subcategory?._id}</Td>
              <Td>{subcategory?.name}</Td>
              <Td>{subcategory?.description}</Td>
              <Td>{subcategory?.categoryId?.name}</Td>
              <Td>
                {subcategory?.image && (
                  <img
                    src={serverName + subcategory?.image}
                    alt={subcategory?.name}
                    width="50"
                  />
                )}
              </Td>
              <Td>
                <EditOutlined
                  onClick={() => handleEdit(subcategory)}
                  style={{ marginRight: 8, cursor: "pointer" }}
                />
                <DeleteOutlined
                  onClick={() => confirmDelete(subcategory._id)}
                  style={{ cursor: "pointer" }}
                />
              </Td>
            </tr>
          ))}
        </Tbody>
      </Table>

      <Drawer
        title={editingSubcategory ? "Edit Subcategory" : "Add Subcategory"}
        width={720}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: "Please enter the subcategory name" },
            ]}
          >
            <Input placeholder="Please enter the subcategory name" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Please enter the subcategory description",
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder="Please enter the subcategory description"
            />
          </Form.Item>
          <Form.Item
            name="categoryId"
            label="Parent Category"
            rules={[
              { required: true, message: "Please select the parent category" },
            ]}
          >
            <Select placeholder="Please select the parent category">
              {categories.map((category) => (
                <Option key={category._id} value={category._id}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="image"
            label="Image"
            valuePropName="file"
            getValueFromEvent={(e) => (e.file ? e : null)}
          >
            <Upload
              name="image"
              listType="picture"
              beforeUpload={() => false} // Prevent automatic upload
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
          <div style={{ height: "20px" }} />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingSubcategory ? "Update" : "Add"} Subcategory
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default Subcategory;
