import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Tr,
  Title,
  TopContainer,
} from "./ProductElement";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  get,
  post,
  productImageURL,
  put,
  remove,
  serverName,
} from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  Button,
  Upload,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  Modal,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

function Product({ type = "", isAdmin = false }) {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [genders, setGenders] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [sizesModalVisible, setSizesModalVisible] = useState(false);
  const [selectedProductSizes, setSelectedProductSizes] = useState([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const token = localStorage.getItem("token");

  const handleDivClick = () => {
    navigate("/products");
  };

  const getAllProducts = async () => {
    try {
      setIsLoading(true);

      const url = `/admin/products?token=${token}`;
      const adminUrl = `/admin/products?token=${token}&type=${type}`;
      let response;
      if (type == "admin") {
        response = await get(adminUrl);
      } else {
        response = await get(url);
      }
      console.log(response?.data);
      if (response.data.success) {
        setProducts(response.data.products);
        setFilteredProducts(response.data.products);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setIsLoading(false);
    }
  };

  const getAllSubcategories = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/subcategories?token=${token}`);
      if (response.data.success) {
        setSubcategories(response.data.subcategories);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      setIsLoading(false);
    }
  };

  const getAllCategories = async () => {
    try {
      const response = await get(`/categories?token=${token}`);
      if (response.data.success) {
        setCategories(response.data.categories);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getAllGender = async () => {
    try {
      const response = await get(`/admin/genders`);
      console.log(response.data);

      if (response.data.success) {
        setGenders(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getAllSizes = async () => {
    try {
      const response = await get(`/admin/sizes`);
      if (response.data.success) {
        setSizes(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    getAllCategories();
    getAllSubcategories();
    getAllSizes();
    getAllGender();
  }, []);

  useEffect(() => {
    console.log(type);
    getAllProducts();
  }, [type]);

  const handleEdit = (product) => {
    setEditingProduct(product);
    form.setFieldsValue({
      ...product,
      sizes: product.sizes?.map((size, index) => ({
        ...size,
        key: index,
      })),
    });
    setDrawerVisible(true);
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this product?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    try {
      const res = await remove(`/admin/product/${id}?token=${token}`);
      if (res.data.success) {
        setProducts(products.filter((product) => product._id !== id));
        setFilteredProducts(
          filteredProducts.filter((product) => product._id !== id)
        );
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const handleAddProduct = () => {
    setEditingProduct(null);
    form.resetFields();
    form.setFieldsValue({ sizes: [{ size: "", price: 0, quantity: 0 }] });
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();

      for (const key in values) {
        if (key === "images" && values[key]?.fileList) {
          values[key].fileList.forEach((file) => {
            formData.append("images", file.originFileObj);
          });
        } else if (key === "sizes") {
          formData.append("sizes", JSON.stringify(values[key]));
        } else {
          formData.append(key, values[key]);
        }
      }

      if (editingProduct) {
        // await put(`/admin/product/${editingProduct._id}`,
        //   formData,
        //   {
        //     headers: {
        //       "Content-Type": "multipart/form-data",
        //     },
        //   }
        // );
     const response = await put(`/admin/product/${editingProduct._id}`,
          formData, 
         {
         headers: {
           "Content-Type": "multipart/form-data",
         },
       })
        setProducts(
          products.map((product) =>
            product._id === editingProduct._id
              ? { ...product, ...values }
              : product
          )
        );
        setFilteredProducts(
          filteredProducts.map((product) =>
            product._id === editingProduct._id
              ? { ...product, ...values }
              : product
          )
        );
      } else {
        const response = await post(
          `/admin/product/new`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setProducts([...products, response.data.product]);
        setFilteredProducts([...filteredProducts, response.data.product]);
      }

      setDrawerVisible(false);
    } catch (error) {
      console.error("Error saving product:", error);
    }
  };

  const handleUploadChange = (info) => {
    if (info.file.status === "done") {
      const uploadedImageUrl = info.file.response.url;
      form.setFieldsValue({ images: uploadedImageUrl });
    }
  };

  const uploadProps = {
    name: "file",
    action: "YOUR_UPLOAD_ENDPOINT",
    headers: {
      authorization: "authorization-text",
    },
    onChange: handleUploadChange,
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = products.filter(
      (product) =>
        product.title.toLowerCase().includes(query) ||
        product.description.toLowerCase().includes(query) ||
        product.material.toLowerCase().includes(query) ||
        product.main_category.toLowerCase().includes(query) ||
        product.sub_categories?.some((subCategory) =>
          subCategory.toLowerCase().includes(query)
        ) ||
        product.color.toLowerCase().includes(query)
    );
    setFilteredProducts(filtered);
  };

  const [showSizeDetails, setSizeDetails] = useState(null);
  const handleViewSizes = (sizes, val) => {
    setSelectedProductSizes(sizes);
    setSizeDetails(val);
    setSizesModalVisible(true);
  };

  const handleCloseSizesModal = () => {
    setSizesModalVisible(false);
    setSizeDetails(null);
  };

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>{isAdmin ? "All " : ""}Products</Title>

        <Input
          placeholder="Search products"
          value={searchQuery}
          onChange={handleSearch}
          style={{ width: 200, margin: 20 }}
        />

        <Button
          type="default"
          onClick={handleAddProduct}
          style={{ margin: 10 }}
        >
          Add Product
        </Button>
      </TopContainer>
      <Table>
        <Thead>
          <tr>
            <Th>No</Th>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Material</Th>
            <Th>Color</Th>
            <Th>Image</Th>
            <Th>Main Categories</Th>
            <Th>Sub Categories</Th>
            <Th>Sizes</Th>
            <Th
              style={{
                maxWidth: "200px",
                minWidth: "200px",
              }}
            >
              Size Details (Price & Admin Commission)
            </Th>
            <Th>Total Quantity</Th>
            <Th>Action</Th>
          </tr>
        </Thead>
        <Tbody>
          {isLoading && <LoadingSpinner />}
          {filteredProducts?.map((product, index) => {
            const totalQuantity = product?.sizes.reduce(
              (acc, size) => acc + size.quantity,
              0
            );
            return (
              <tr key={product._id}>
                <Td>{index + 1}</Td>
                <Td>{product.title}</Td>
                <Td
                  style={{
                    maxWidth: "200px",
                    minWidth: "200px",
                  }}
                >
                  {product.description}
                </Td>
                <Td>{product.material}</Td>
                <Td>{product.color}</Td>
                <Td>
                  <img
                    src={`${productImageURL}${product.images[0]}`}
                    alt={product.title}
                    width="50"
                  />
                </Td>
                <Td>{product.main_category}</Td>
                <Td>
                  {product.sub_categories?.map((subcategory) => (
                    <div key={subcategory}>{subcategory}</div>
                  ))}
                </Td>
                <Td>
                  <Button
                    type="link"
                    onClick={() => handleViewSizes(product.sizes, 0)}
                  >
                    View Sizes
                  </Button>
                </Td>
                <Td>
                  <Button
                    type="link"
                    onClick={() => handleViewSizes(product.sizes, 1)}
                  >
                    View Details
                  </Button>
                </Td>
                <Td>{totalQuantity}</Td>
                <Td>
                  <EditOutlined
                    onClick={() => handleEdit(product)}
                    style={{ marginRight: 8, cursor: "pointer" }}
                  />
                  <DeleteOutlined
                    onClick={() => confirmDelete(product._id)}
                    style={{ cursor: "pointer" }}
                  />
                </Td>
              </tr>
            );
          })}
        </Tbody>
      </Table>

      <Drawer
        title={editingProduct ? "Edit Product" : "Add Product"}
        width={720}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            name="title"
            label="Title"
            rules={[
              { required: true, message: "Please enter the product title" },
            ]}
          >
            <Input placeholder="Please enter the product title" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: "Please enter the product description",
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Please enter the product description"
            />
          </Form.Item>
          <Form.Item
            name="material"
            label="Material"
            rules={[
              { required: true, message: "Please enter the product material" },
            ]}
          >
            <Input placeholder="Please enter the product material" />
          </Form.Item>
          <Form.Item
            name="color"
            label="Color"
            rules={[
              { required: true, message: "Please enter the product color" },
            ]}
          >
            <Input placeholder="Please enter the product color" />
          </Form.Item>

          <Form.Item
            name="gender"
            label="Gender"
            rules={[{ required: true, message: "Please select a Gender" }]}
          >
            <Select placeholder="Please select the parent category">
              {genders?.map((gender) => (
                <Option key={gender._id} value={gender._id}>
                  {gender?.gender}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="main_category"
            label="Main Category"
            rules={[
              { required: true, message: "Please select a main category" },
            ]}
          >
            <Select placeholder="Please select the parent category">
              {categories?.map((category) => (
                <Option key={category._id} value={category._id}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="sub_categories"
            label="Sub Categories"
            rules={[
              { required: true, message: "Please select sub categories" },
            ]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Please select sub categories"
            >
              {subcategories?.map((subcategory) => (
                <Option key={subcategory._id} value={subcategory._id}>
                  {subcategory.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.List name="sizes">
            {(fields, { add, remove }) => (
              <>
                {fields?.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key} style={{ display: "flex", marginBottom: 8 }}>
                    <Form.Item
                      name={[name, "size"]}
                      fieldKey={[fieldKey, "size"]}
                      label="Size"
                      rules={[{ required: true, message: "Size is required" }]}
                      style={{ marginRight: 8, width: "100px" }}
                    >
                      <Select placeholder="Size">
                        {sizes?.map((size) => (
                          <Option key={size._id} value={size.size}>
                            {size?.size}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "price"]}
                      fieldKey={[fieldKey, "price"]}
                      label="Price"
                      rules={[{ required: true, message: "Price is required" }]}
                      style={{ marginRight: 8 }}
                    >
                      <InputNumber placeholder="Price" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "discount"]}
                      fieldKey={[fieldKey, "discount"]}
                      label="Discount"
                      rules={[
                        { required: true, message: "Discount is required" },
                      ]}
                      style={{ marginRight: 8 }}
                    >
                      <InputNumber placeholder="Discount" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "quantity"]}
                      fieldKey={[fieldKey, "quantity"]}
                      label="Quantity"
                      rules={[
                        { required: true, message: "Quantity is required" },
                      ]}
                      style={{ marginRight: 8 }}
                    >
                      <InputNumber placeholder="Quantity" />
                    </Form.Item>
                    <Button
                      type="danger"
                      style={{ alignSelf: "center" }}
                      onClick={() => remove(name)}
                      icon={<DeleteOutlined />}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "100%" }}
                >
                  Add Size
                </Button>
              </>
            )}
          </Form.List>
          <Form.Item
            name="images"
            label="Images"
            rules={[
              { required: true, message: "Please upload product images" },
            ]}
          >
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button style={{ marginTop: 20 }} type="primary" htmlType="submit">
              {editingProduct ? "Update" : "Add"} Product
            </Button>
          </Form.Item>
        </Form>
      </Drawer>

      <Modal
        title="Product Sizes"
        visible={sizesModalVisible}
        onCancel={handleCloseSizesModal}
        bodyStyle={{ overflowY: "auto" }}
        width={showSizeDetails == 1 ? "70%" : "50%"}
        footer={[
          <Button key="close" onClick={handleCloseSizesModal}>
            Close
          </Button>,
        ]}
      >
        <Table>
          <Thead>
            <tr>
              <Th>Size</Th>
              <Th>Sale Price</Th>
              <Th>Quantity</Th>
              {showSizeDetails === 1 && (
                <>
                  <Th>Discount %</Th>
                  <Th>Discount £</Th>
                  <Th>After Discount</Th>
                  <Th>Admin Fee</Th>
                  <Th>Net Amount</Th>
                </>
              )}
            </tr>
          </Thead>
          <Tbody>
            {selectedProductSizes.map((size, index) => (
              <Tr key={index}>
                <Td>{size.size}</Td>
                <Td>{size.price}</Td>
                <Td>{size.quantity}</Td>

                {showSizeDetails === 1 && (
                  <>
                    <Td>
                      {((size.discount * 100) / size.price) % 1 === 0
                        ? (size.discount * 100) / size.price + " %"
                        : ((size.discount * 100) / size.price).toFixed(2) +
                          " %"}
                    </Td>
                    <Td>{size.discount}</Td>
                    <Td>{size.price - size.discount}</Td>
                    <Td>
                      {(size.price * 0.2)?.toString()?.slice(0, 5)} - (20%)
                    </Td>
                    <Td>{(size.price * 0.8).toString()?.slice(0, 5)}</Td>
                  </>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Modal>
    </div>
  );
}

export default Product;
